export default {
  methods: {
    showAppbarAndDrawer () {
      const path = this.$route.path
      if (path.indexOf('/login') !== -1 ||
        path.indexOf('/about') !== -1 ||
        path.indexOf('/release') !== -1 ||
        path.indexOf('/privacy') !== -1 ||
        path.indexOf('/term') !== -1) {
        return false
      }
      return true
    },
    createSampleData () {
      const data = {
        shikakuList: [
          {
            shutokubi: '2012-05-20',
            yuukoukigen: null,
            name: '普通自動車第一種運転免許',
            koushinkigen: '2023-08-15',
            id: '11111111-1111-47b4-9bff-b728686514cc',
          },
          {
            shutokubi: '2020-04-18',
            yuukoukigen: null,
            name: '基本情報技術者試験',
            koushinkigen: null,
            id: '11111111-2222-47b4-9bff-b728686514bb',
          },
          {
            yuukoukigen: null,
            id: '11111111-3333-4807-804b-2f301ee3a5bb',
            koushinkigen: '2023-03-18',
            name: '宅地建物取引士',
            shutokubi: '2018-03-18',
          },
          {
            yuukoukigen: null,
            id: '11111111-3333-4807-804b-2f301ee3a5bb',
            koushinkigen: null,
            name: '簿記3級',
            shutokubi: '2016-07-30',
          },
          {
            yuukoukigen: null,
            id: '11111111-4444-4807-804b-2f301ee3a5bb',
            koushinkigen: null,
            name: 'TOEIC ○○点',
            shutokubi: '2022-01-15',
          },
        ],
        studyList: [
          {
            shikenbi: this.calcRelativeDate(21),
            kekkahappyou: null,
            name: 'TOEIC',
            id: '22222222-1111-46c0-9430-91af60e741bb',
          },
          {
            shikenbi: this.calcRelativeDate(-10),
            kekkahappyou: this.calcRelativeDate(65),
            name: '応用情報技術者試験',
            id: '22222222-2222-46c0-9430-91af60e741bb',
          },
          {
            kekkahappyou: null,
            shikenbi: this.calcRelativeDate(180),
            id: '22222222-3333-4b15-a767-27e69f30dcbb',
            name: '簿記2級',
          },
        ],
        kininaruList: [
          {
            name: '中小企業診断士試験',
            id: '33333333-1111-46c0-9430-91af60e741bb',
          },
          {
            name: '社会保険労務士',
            id: '33333333-2222-46c0-9430-91af60e741bb',
          },
        ],
        taskList: [
          {
            allPage: '500',
            kanryouyoteibi: '2023-10-31',
            nowPage: 350,
            name: 'サンプルテキスト',
            parentId: '595f405a-e383-4b15-a767-27e69f30dcbb',
          },
        ],
      }
      return data
    },
    calcRelativeDate (diff) {
      const today = new Date()
      const taegetDay = new Date(today)
      taegetDay.setDate(today.getDate() + diff)
      const year = taegetDay.getFullYear()
      const month = String(taegetDay.getMonth() + 1).padStart(2, '0')
      const day = String(taegetDay.getDate()).padStart(2, '0')
      const formattedToday = `${year}-${month}-${day}`
      return formattedToday
    },
  },
}
