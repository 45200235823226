<template>
  <v-navigation-drawer
    v-if="showAppbarAndDrawer()"
    id="core-navigation-drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
    dark
    temporary
    color="grey darken-4"
  >
    <v-list>
      <v-list-item class="d-flex justify-center">
        <v-icon
          size="50"
          color="success"
          @click="$router.push('/')"
         >
           mdi-certificate-outline
         </v-icon>
      </v-list-item>
      <v-list-item class="d-flex justify-center">
        <v-list-item-title
          class="text-h3 font-weight-bold"
          style="text-align: center"
        >
          資格管理アプリ
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list
      expand
      nav
    >
      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        />
        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
    </v-list>
    <!--
    <template v-slot:append>
      <base-item
        :item="{
          title: $t('upgrade'),
          icon: 'mdi-package-up',
          to: '/upgrade',
        }"
      />
    </template>
    -->
  </v-navigation-drawer>
</template>

<script>
  import { mapState } from 'vuex'
  import showAppbarAndDrawer from '../../mixin/common'
  export default {
    name: 'DashboardCoreDrawer',
    mixins: [
      showAppbarAndDrawer,
    ],
    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      items: [
        {
          icon: 'mdi-badge-account-horizontal',
          title: '資格一覧',
          to: '/',
        },
        {
          icon: 'mdi-timeline',
          title: 'タイムライン',
          to: '/timeline',
        },
        /*
        {
          icon: 'mdi-certificate-outline',
          title: '資格管理アプリとは',
          to: '/about',
        },
        {
          icon: 'mdi-calendar-range',
          title: '資格カレンダー',
          to: '/calendar',
        },
        {
          icon: 'mdi-timer-sand',
          title: '勉強進捗管理',
          to: '/progress',
        },
        */
      ],
    }),
    computed: {
      ...mapState(['barColor']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
